import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { getFormattedAmount } from "components/Helpers";
import Pagination from "components/interface/Pagination";
import { IAccountProperties } from "pear-state/account_slice";
import SnackbarContext from "pear-state/snackbar_context";
import { ITransactionResponse } from "pear-state/transaction_slice";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface TransactionsTableProps {
  transactions: ITransactionResponse[];
  accounts: IAccountProperties[];
}

export default function TransactionsTable({ transactions, accounts }: TransactionsTableProps) {
  const snackbarCtx = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 50;
    const lastPageIndex = firstPageIndex + 50;
    return transactions.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, transactions]);
  const findAccount = (uuid: string) => {
    return accounts.find((account) => account.uuid === uuid);
  };

  const handleDelete = (transaction_uuid: string) => {
    if (confirm("Do you want to delete this transaction?")) {
      axios.delete(`transactions/${transaction_uuid}`).then(() => {
        snackbarCtx.displayMessage("Transaction deleted", "bg-pear-opal");
        navigate(0);
      });
    }
  };

  return (
    <div className="mt-4 h-full">
      <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8 h-full">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Account
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Amount
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Details
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {paginatedData &&
                  paginatedData.map((transaction, transactionIdx) => (
                    <tr key={transaction.uuid} className={transactionIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm ">
                        <a
                          href={`/accounts/${transaction.account_uuid}`}
                          className="text-pear-purple hover:text-indigo-600"
                        >
                          {findAccount(transaction.account_uuid)?.name}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transaction.transaction_date as string}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {getFormattedAmount(transaction.total_amount, transaction.currency)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.details}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-row gap-2">
                          <a href={`/transactions/${transaction.uuid}/edit`} className="text-gray-600 hover:text-black">
                            <PencilSquareIcon className="h-5 w-5" />
                          </a>
                          <button
                            onClick={() => handleDelete(transaction.uuid)}
                            className="text-gray-600 hover:text-black"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalCount={transactions.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
