import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface ITransaction {
  transaction_uuid?: string;
  transactionType: string;
  currency: "CAD" | "USD";
  account: string;
  transactionDate: string;
  amount: number;
  pricePerUnit: number;
  numberOfUnits: number;
  commission: number;
  symbol: string;
}

export interface ITransactionResponse {
  uuid: string;
  total_amount: number;
  currency: "CAD" | "USD";
  transaction_date: string;
  transaction_type: string;
  account_uuid: string;
  details: string;
  symbol: string;
  price_per_unit: number;
  number_of_units: number;
  commission: number;
}

export interface IAccountMapping {
  broker_name: string;
  account_uuid: string;
  brokerage_account_number: string;
}

export const createNewTransaction = createAsyncThunk("transactions/createTransaction", async (transaction: any) => {
  const { data } = await axios.post(`transactions`, transaction);
  return data;
});

export const updateExistingTransaction = createAsyncThunk(`transactions/updateTransaction`, async (updateData: { transaction_uuid: string, transactionData: any}) => {
  const { transaction_uuid, transactionData } = updateData;
  const { data } = await axios.put(`transactions/${transaction_uuid}`, transactionData);
  return data;
});

export const transactionSlice = createSlice({
  name: "transactions",
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createNewTransaction.fulfilled, (state, action) => {})
      .addCase(updateExistingTransaction.fulfilled, (state, action) => {});
  },
});

export default transactionSlice.reducer;
