import { useDispatch } from "react-redux";
import { useForm, useFieldArray, SubmitHandler, WatchObserver } from "react-hook-form";
import {
  createNewTransaction,
  ITransactionResponse,
  updateExistingTransaction,
} from "../../pear-state/transaction_slice";
import React, { useEffect, useContext } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { fetchStocks } from "../../pear-state/stock_slice";
import SnackbarContext from "../../pear-state/snackbar_context";
import { transactionFields, TransactionType } from "./transaction_form/FieldMapping";
import { IAccountProperties } from "pear-state/account_slice";
import { AppDispatch } from "pear-state/store";

interface TransactionFormProps {
  accounts: IAccountProperties[];
  transactions?: ITransactionResponse[];
  action: "Create" | "Update" | "BulkCreate";
}

interface RowValueProps {
  account: string | null;
  transactionType: TransactionType;
  symbol: string;
  numberOfUnits: string | number;
  pricePerUnit: string | number;
  amount: string | number;
  transactionDate: string;
  currency: "CAD" | "USD";
  commission: string | number;
}

interface FormSubmissionType {
  data: RowValueProps[];
}

const TransactionForm = ({ accounts, transactions = [], action }: TransactionFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const snackbarCtx = useContext(SnackbarContext);

  const mappedTransactions = transactions.map((transaction) => {
    return {
      account: transaction.account_uuid || null,
      transactionType: (transaction.transaction_type as TransactionType) || null,
      symbol: transaction.symbol || "",
      numberOfUnits: transaction.number_of_units || "",
      pricePerUnit: transaction.price_per_unit || "",
      amount: transaction.total_amount || "",
      transactionDate: transaction.transaction_date || new Date().toLocaleDateString("en-CA").split("T")[0],
      currency: transaction.currency || null,
      commission: transaction.commission || "",
    };
  });

  const defaultRowValues: RowValueProps = {
    account: null,
    transactionType: TransactionType.Buy,
    symbol: "",
    numberOfUnits: "",
    pricePerUnit: "",
    amount: "",
    transactionDate: new Date().toLocaleDateString("en-CA").split("T")[0],
    currency: "CAD",
    commission: "",
  };

  const {
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormSubmissionType>({
    defaultValues: {
      data: [defaultRowValues],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "data",
  });

  const createTransaction = (data: FormSubmissionType) => {
    dispatch(createNewTransaction(data))
      .unwrap()
      .then((response) => {
        snackbarCtx.displayMessage(response.message, response.status === "success" ? "bg-pear-opal" : "bg-red-500");
        dispatch(fetchStocks());
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const updateTransaction = (data: FormSubmissionType) => {
    dispatch(
      updateExistingTransaction({
        transaction_uuid: transactions[0].uuid,
        transactionData: data,
      })
    )
      .unwrap()
      .then((response) => {
        snackbarCtx.displayMessage(response.message, response.status === "success" ? "bg-pear-opal" : "bg-red-500");
        dispatch(fetchStocks());
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const onSubmit: SubmitHandler<FormSubmissionType> = (submissionData) => {
    action === "Update" ? updateTransaction(submissionData) : createTransaction(submissionData);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ data: [defaultRowValues] });
    } else {
      if (action !== "Create") {
        reset({ data: mappedTransactions });
      }
    }
  }, [isSubmitSuccessful, reset]);

  function renderButtons() {
    return (
      <div className="m-2">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-md bg-pear-purple px-4 py-1 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
        >
          Save
        </button>
      </div>
    );
  }

  const renderRow = (data: any, index: number) => {
    // @ts-ignore
    const currentTransactionType = watch(`data[${index}].transactionType`);
    // @ts-ignore
    const fieldsToRender = transactionFields[currentTransactionType];

    return (
      <div key={data.id} className="flex flex-col md:flex-row md:flex-wrap xl:flex-nowrap pt-4">
        {fieldsToRender.map((inputField: any) => {
          return (
            <div key={`${data.id}-${inputField.formName}`} className="flex flex-row xl:flex-col mr-1 mt-2">
              <inputField.component
                formName={`data[${index}].${inputField.formName}`}
                control={control}
                accounts={accounts}
                displayName={inputField.displayName}
                rowIndex={index}
              />
            </div>
          );
        })}
        <div className="flex flex-col mr-1 mt-4">
          <button className="text-gray-600 hover:text-black" onClick={() => remove(index)}>
            <TrashIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  };

  function renderNewRowButton() {
    return (
      <div className="flex flex-row mt-4 justify-center w-full">
        <button
          className="border border-gray-200 rounded-md bg-slate-200 text-gray-500 hover:bg-slate-300 w-1/2 p-1 text-sm"
          onClick={() => append(defaultRowValues)}
        >
          + New row
        </button>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {fields.map((data, index) => {
          return renderRow(data, index);
        })}
        {action === "Update" ? null : renderNewRowButton()}
        {renderButtons()}
      </form>
    </>
  );
};

export default TransactionForm;
