import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchAccounts } from "../../pear-state/account_slice";
import EmptyState from "../EmptyState";
import Skeleton from "../interface/Skeleton";
import NoTransactionsState from "../NoTransactionsState";
import axios from "axios";
import { AppDispatch, RootState } from "pear-state/store";
import { ITransactionResponse } from "pear-state/transaction_slice";
import TransactionFilterForm from "./TransactionFilterForm";
import TransactionsTable from "./TransactionsTable";
import RecentTransactionsTable from "./RecentTransactionsTable";

const Transactions = () => {
  const [transactions, setTransactions] = useState<ITransactionResponse[]>([]);
  const [transactionsStatus, setTransactionsStatus] = useState("idle");
  const [filters, setFilters] = useState({});

  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const accountsStatus = useSelector((state: RootState) => state.accounts.status);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (accounts.length === 0) {
      dispatch(fetchAccounts());
    }

    setTransactionsStatus("loading");

    axios.get("transactions").then((response) => {
      setTransactions(response.data);
      setTransactionsStatus("succeeded");
    });
  }, [dispatch]);

  if (accountsStatus === "succeeded" && accounts.length === 0) {
    return <EmptyState />;
  }

  if (transactionsStatus === "loading") {
    return <Skeleton />;
  }

  if (transactionsStatus === "succeeded" && transactions.length === 0) {
    return <NoTransactionsState />;
  }

  const onFilterSubmit = (data: any) => {
    const filteredObj = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== ""));
    setFilters(filteredObj);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    let match = true;
    Object.entries(filters).forEach(([key, value]) => {
      // @ts-ignore
      if (transaction[key] !== value) {
        match = false;
      }
    });

    return match;
  });

  return (
    <div className="w-full">
      <div className="sm:flex sm:items-center">
        <div className="flex sm:flex-auto gap-2">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
          >
            <a href="/transactions/new">New Transaction</a>
          </button>
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
          >
            <a href="/transactions/upload">Upload files</a>
          </button>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <TransactionFilterForm accounts={accounts} onFilterSubmit={onFilterSubmit} />
      <RecentTransactionsTable transactions={transactions} />
      <TransactionsTable transactions={filteredTransactions} accounts={accounts} />
    </div>
  );
};

export default Transactions;
