import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { Axios, AxiosError } from "axios";

enum StoreStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}
interface UserStoreStatus {
  networthDistributionStatus: StoreStatus;
  accountTypeDistributionStatus: StoreStatus;
  sectorDistributionStatus: StoreStatus;
  exchangeRateStatus: StoreStatus;
  overallPictureStatus: StoreStatus;
  userStatus: StoreStatus;
  error?: string;

  networthDistribution: any[];
  accountTypeDistribution: any[];
  sectorDistribution: any[];
  exchangeRate: number;
  overallPicture: any;
  loggedIn: boolean;
  isAdmin: boolean;
  isDemo: boolean;
}

const initialState: UserStoreStatus = {
  networthDistribution: [],
  accountTypeDistribution: [],
  sectorDistribution: [],
  exchangeRate: 1.34,
  overallPicture: null,
  loggedIn: false,
  isAdmin: false,
  isDemo: false,

  networthDistributionStatus: StoreStatus.IDLE,
  accountTypeDistributionStatus: StoreStatus.IDLE,
  sectorDistributionStatus: StoreStatus.IDLE,
  exchangeRateStatus: StoreStatus.IDLE,
  overallPictureStatus: StoreStatus.IDLE,
  userStatus: StoreStatus.IDLE,
  error: undefined,
};

export const fetchUserState = createAsyncThunk("user/fetch", async () => {
  const { data } = await axios.get(`get-user`);
  return data;
});

export const createUser = createAsyncThunk("user/create", async (input: { email: string; password: string }) => {
  const { data } = await axios.post(`signup`, input);
  return data;
});

export const createDemoUser = createAsyncThunk("user/createDemo", async () => {
  const { data } = await axios.post(`create-demo`);
  return data;
});

export const loginUser = createAsyncThunk("user/login", async (input: { email: string; password: string }) => {
  let data = null;
  let error = null;

  try {
    const response = await axios.post(`login`, input);
    data = response.data;
  } catch (e) {
    const err = e as AxiosError;
    let errors = err.response?.data?.errors;

    if (errors.length > 0) {
      error = errors[0];
    }
  }

  return { data, error };
});

export const logoutUser = createAsyncThunk("user/logout", async () => {
  const { data } = await axios.post(`logout`);
  return data;
});

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (input: { email: string }) => {
  const { data } = await axios.post(`password-forgot`, input);
  return data;
});

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (input: { email: string; password: string; token: string }) => {
    const { data } = await axios.post(`password-reset`, input);
    return data;
  }
);

export const fetchNetworthDistribution = createAsyncThunk(
  "networth/fetch",
  async (balanceLookbackInDays: number | null) => {
    const lookbackPeriod = balanceLookbackInDays ? `?lookback=${balanceLookbackInDays}` : "";
    const { data } = await axios.get(`reporting/balance-trend${lookbackPeriod}`);
    return data;
  }
);

export const fetchAccountTypeDistribution = createAsyncThunk("accountType/fetch", async () => {
  const { data } = await axios.get("reporting/account-type-distribution");
  return data;
});

export const fetchSectorDistribution = createAsyncThunk("sector/fetch", async () => {
  const { data } = await axios.get("reporting/sector-distribution");
  return data;
});

export const fetchExchangeRate = createAsyncThunk("exchangeRate/fetch", async () => {
  const res = await axios.get(`exchange-rate`);
  return res.data.cad;
});

export const fetchOverallPicture = createAsyncThunk("overallPicture/fetch", async () => {
  const res = await axios.get(`reporting/overall-picture`);
  return res.data;
});

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  // extraReducers are to process actions defined outside of our slice
  extraReducers(builder) {
    builder
      .addCase(fetchNetworthDistribution.pending, (state, action) => {
        state.networthDistributionStatus = StoreStatus.LOADING;
      })
      .addCase(fetchNetworthDistribution.fulfilled, (state, action) => {
        state.networthDistributionStatus = StoreStatus.SUCCEEDED;
        state.networthDistribution = action.payload;
      })
      .addCase(fetchNetworthDistribution.rejected, (state, action) => {
        state.networthDistributionStatus = StoreStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchAccountTypeDistribution.pending, (state, action) => {
        state.accountTypeDistributionStatus = StoreStatus.LOADING;
      })
      .addCase(fetchAccountTypeDistribution.fulfilled, (state, action) => {
        state.accountTypeDistributionStatus = StoreStatus.SUCCEEDED;
        state.accountTypeDistribution = action.payload;
      })
      .addCase(fetchAccountTypeDistribution.rejected, (state, action) => {
        state.accountTypeDistributionStatus = StoreStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchSectorDistribution.pending, (state, action) => {
        state.sectorDistributionStatus = StoreStatus.LOADING;
      })
      .addCase(fetchSectorDistribution.fulfilled, (state, action) => {
        state.sectorDistributionStatus = StoreStatus.SUCCEEDED;
        state.sectorDistribution = action.payload;
      })
      .addCase(fetchSectorDistribution.rejected, (state, action) => {
        state.sectorDistributionStatus = StoreStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchExchangeRate.pending, (state, action) => {
        state.exchangeRateStatus = StoreStatus.LOADING;
      })
      .addCase(fetchExchangeRate.fulfilled, (state, action) => {
        state.exchangeRateStatus = StoreStatus.SUCCEEDED;
        state.exchangeRate = action.payload;
      })
      .addCase(fetchExchangeRate.rejected, (state, action) => {
        state.exchangeRateStatus = StoreStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchOverallPicture.pending, (state, action) => {
        state.overallPictureStatus = StoreStatus.LOADING;
      })
      .addCase(fetchOverallPicture.fulfilled, (state, action) => {
        state.overallPictureStatus = StoreStatus.SUCCEEDED;
        state.overallPicture = action.payload;
      })
      .addCase(fetchOverallPicture.rejected, (state, action) => {
        state.overallPictureStatus = StoreStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchUserState.pending, (state, action) => {
        state.userStatus = StoreStatus.LOADING;
      })
      .addCase(fetchUserState.fulfilled, (state, action) => {
        state.userStatus = StoreStatus.SUCCEEDED;
        state.loggedIn = action.payload.logged_in;
      })
      .addCase(fetchUserState.rejected, (state, action) => {
        state.userStatus = StoreStatus.FAILED;
        state.error = action.error.message;
        state.loggedIn = false;
      });
  },
});

export default userSlice.reducer;
