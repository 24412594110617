import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Accounts from "./components/Accounts";
import AccountFormPage from "./components/AccountForm";
import HealthCheck from "./components/HealthCheck";
import Dashboard from "./components/Dashboard";
import Navigation from "./components/Navigation";
import Stocks from "./components/Stocks";
import Account from "./components/Account";
import Transactions from "./components/transactions/Transactions";
import TransactionFormContainer from "./components/transactions/TransactionFormContainer";
import SymbolDetails from "./components/SymbolDetails";
import SnackbarContext from "./pear-state/snackbar_context";
import Snackbar from "./components/interface/Snackbar";
import TransactionUploadPage from "./components/transactions/TransactionUploadPage";
import Skeleton from "components/interface/Skeleton";
import AuthPage, { AuthPageVariant } from "components/AuthPage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import { fetchUserState } from "pear-state/user_slice";
import ForgotPasswordPage from "components/ForgotPasswordPage";
import ResetPasswordPage from "components/ResetPasswordPage";
import Impersonate from "components/Impersonate";
import CreateDemoPage from "components/CreateDemoPage";
import Dividends from "components/Dividends";
import Housing from "components/Housing";

const App = () => {
  const snackbarCtx = useContext(SnackbarContext);
  const dispatch = useDispatch<AppDispatch>();
  // TODO: Store this in localstorage
  const isLoggedIn = useSelector((state: RootState) => state.users.loggedIn);
  const isAdmin = useSelector((state: RootState) => state.users.isAdmin);
  const userFetchStatus = useSelector((state: RootState) => state.users.userStatus);

  useEffect(() => {
    if (userFetchStatus == StoreStatus.IDLE) {
      dispatch(fetchUserState());
    }
  }, [dispatch]);

  const renderApp = () => {
    return (
      <div className="bg-gray-50 min-h-full overflow-scroll">
        <Router>
          <Routes>
            <Route path="/login" element={<AuthPage variant={AuthPageVariant.LOGIN} isLoggedIn={isLoggedIn} />} />
            <Route path="/signup" element={<AuthPage variant={AuthPageVariant.SIGNUP} isLoggedIn={isLoggedIn} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/create-demo" element={<CreateDemoPage />} />

            <Route element={isLoggedIn ? <Navigation /> : <Navigate to="/login" />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/impersonate" element={isAdmin ? <Impersonate /> : <Navigate to="/" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/create-account" element={<AccountFormPage action={"Create"} />}></Route>
              <Route path="/transactions/new" element={<TransactionFormContainer />}></Route>
              <Route path="/transactions/upload" element={<TransactionUploadPage />}></Route>
              <Route path="/accounts" element={<Accounts />}></Route>
              <Route path="/accounts/:account_uuid" element={<Account />}></Route>
              <Route path="/accounts/:account_uuid/edit" element={<AccountFormPage action={"Update"} />}></Route>
              <Route path="/transactions/:transaction_uuid/edit" element={<TransactionFormContainer />}></Route>
              <Route path="/equities" element={<Stocks />}></Route>
              <Route path="/dividends" element={<Dividends />}></Route>
              <Route path="/housing" element={<Housing />}></Route>
              <Route path="/transactions" element={<Transactions />}></Route>
              <Route path="/symbols/:symbol" element={<SymbolDetails />}></Route>
              <Route path="/health-check" element={<HealthCheck />}></Route>
            </Route>
          </Routes>
        </Router>
        <div className="fixed z-50 bottom-4 right-4">{<Snackbar />}</div>
      </div>
    );
  };

  if (userFetchStatus === StoreStatus.LOADING || userFetchStatus === StoreStatus.IDLE) {
    return <Skeleton />;
  }

  return renderApp();
};

export default App;
