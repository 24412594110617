import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "../../pear-state/account_slice";
import FileUploadStep, { IBrokerages } from "./bulk_uploads/FileUploadStep";
import ReviewAccountsStep from "./bulk_uploads/ReviewAccountsStep";
import ReviewTransactionsStep from "./bulk_uploads/ReviewTransactionsStep";
import Skeleton from "components/interface/Skeleton";
import axios from "axios";
import { AppDispatch, RootState } from "pear-state/store";

const TransactionUploadPage = () => {
  const [bulkUploadFormState, setBulkUploadFormState] = useState({
    brokerage: IBrokerages.None,
    brokerFile: "",
    step: 0,
    transactions: [],
    accountMappings: [],
  });
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (accounts.length === 0) {
      dispatch(fetchAccounts());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      bulkUploadFormState.transactions.length == 0 &&
      bulkUploadFormState.step == 2 &&
      bulkUploadFormState.brokerage != ""
    ) {
      const formData = new FormData();
      formData.append("file", bulkUploadFormState.brokerFile);
      formData.append("brokerage", bulkUploadFormState.brokerage);

      axios
        .post("transactions/parse-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setBulkUploadFormState((prevState) => {
            return {
              ...prevState,
              transactions: response.data.transactions,
            };
          });
        });
    }
  }, [bulkUploadFormState.transactions, bulkUploadFormState.step]);

  if (accounts.length === 0) {
    return <Skeleton />;
  }

  const goBack = () => {
    setBulkUploadFormState({
      ...bulkUploadFormState,
      step: bulkUploadFormState.step <= 0 ? 0 : bulkUploadFormState.step - 1,
    });
  };

  const conditionalComponent = () => {
    switch (bulkUploadFormState.step) {
      case 0:
        return (
          <FileUploadStep
            brokerage={bulkUploadFormState.brokerage}
            brokerFile={bulkUploadFormState.brokerFile}
            setBulkUploadFormState={setBulkUploadFormState}
          />
        );
      case 1:
        return (
          <ReviewAccountsStep
            accounts={accounts}
            accountMappings={bulkUploadFormState.accountMappings}
            goBack={goBack}
            setBulkUploadFormState={setBulkUploadFormState}
          />
        );
      case 2:
        return (
          <ReviewTransactionsStep transactions={bulkUploadFormState.transactions} accounts={accounts} />
        );
      case 3:
        return <div>Step 3</div>;
      default:
        return <div>Step 4</div>;
    }
  };

  return conditionalComponent();
};

export default TransactionUploadPage;
